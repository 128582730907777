@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.Lunch, .Dinner, .Daily, .Brunch, .MenuPage{@include sec-default(); background-color:$menuBlue; padding: 40px 0px;
  .stickyCatAlt{
    @include sec-default(); background-color: $gold; padding: 5px 0; margin:0 0; top:0; text-align: center; display:none;
    // .scInner {@include center-block(); box-shadow: 0px 8px 15px rgba($black,.25);}
    .catItem{display: inline-block; color:$white; font-weight: bold; font-family: $lus; font-size: 1.1rem; text-shadow:0px 1px 2px rgba($black,.4); margin: 5px 2%;}
  }

  .menuArea{@include sec-default();background-color: $white; padding:50px 0;}
  .titleRow{@include sec-default();text-align: center;border-top:4px solid $menuBlue;border-bottom:4px solid $menuBlue;padding:10px 0;margin-bottom:15px;}
  .menuRow {@include sec-default();position: relative; z-index: 0;
    &::before{@include before(); @include sec-bgimage('./images/compass.png'); background-position: center center; z-index: -2;}
    &::after{@include before(); background-color: rgba($white, .93);}
  }

  .menuBox{display: inline-block;width:45%;margin-left:3%;vertical-align:top;}
  .menuBoxFull {width:94%; margin-left: 3%;}

  p, h3, h4, h1{font-family:$lus;text-transform:uppercase;color:$menuBlue;}
  h4{color: $gold;margin-bottom:0;
    span{float:right;color:$menuBlue;text-transform:none;}
  }
  p{margin-top:0px;}
  p.sub{text-align:center;color:$black;}
  h1, h3{color:$menuBlue;font-weight:bold;}
  h1{border-top:2px solid $gold;border-bottom:2px solid $gold;padding:15px 0;line-height:1.1em;}
  h1 span{font-size:19px;letter-spacing:1px;}
  h1 a{color:#1e87f0;}
  h3{text-align:center;padding-bottom:0px;margin-top:20px;text-decoration:underline;
    font-size: 1.6rem; width:250px; @include center-block();
    &.fwH3{width:100%;}
  }

  .menuRow.monthlySpecials{text-align: center; padding-bottom:20px;
    &::before{display: none;}
    &::after{background-color: rgba($gold, .1);}
    h4 {font-weight:bold;
      b{color: $color1; font-weight: normal;}
      span{float:none; font-weight: normal;}
    }
  }

  .menuBox h4 span, .menuBoxFull h4 span{display: none;}

  .menuImg{display:inline-block;text-align:center;}
  .menuImg.lunch{float:left;width:55%;}
  .menuImg.dinner{float:right;width:42%;}
  .menuImg.equal{width:48%;}
  .menuImg.equal+.equal{float:right;}
  
  .menuImg.solo{@include center-block();max-width:600px;
    a:hover{opacity:.9;}
  }

  p:not(.pdfV){color:$white;}
  p:not(.pdfV, .menuTitle){font-size:20px;margin-bottom:0px;}
  p.plain{font-size:18px;color:darken($gold,7%);font-family:unset;text-transform:none;}
  .pdfV{color:$white;text-align:center;border:none;margin-bottom:30px;max-width:500px;@include center-block();line-height:1.3em;}
  h1.pdfV{font-size:25px;}
  p.pdfV{font-family:$port;}
  p.menuTitle{letter-spacing:.8px;margin-bottom:10px;}
  p.menuTitle span.line1{font-size:25px;line-height:1.1em;}
  p.menuTitle span.line2{font-size:23px;line-height:1.2em;}

  .special{float:left;width:55%;margin-top:30px;padding:5px;}
  .msInner{@include sec-default();padding:30px 20px;background-color:$white;
    position:relative;z-index:0;
    &::before{@include before();width:95%;height:90%;left:2.5%;top:5%;border:1px solid rgba($gold,.5);}
    span.sm{text-transform:uppercase;letter-spacing:3px;font-size:15px;}
    h4{margin:0 0 20px;font-size:20px;line-height:1.2em;}
    p{color:$color1;font-size:17px;margin-bottom:0;}
    span.price{color:$gold;}
  }

  // equal img
  .menuImgE{text-align:center;
    &.hlfL{margin-bottom:20px;}
  }
  @media (min-width:993px){
    .menuImgE{display:inline-block;width:49%;
      &.hlfL{float:left;margin-bottom:0;}
      &.hlfR, &+.menuImgE{float:right;}
    }
  }

  @include bp(tb) {padding-top:0;
    .menuArea{padding: 20px 0 30px;}
    .stickyCatAlt{display:block;}
    .inside.stip{width:95%;}

    h4{font-weight: bold;}
    h4 span{float: none; margin-left: 5%;}
    .menuRow.monthlySpecials h4 span{margin-left:0;}
    p.plain{font-size:17px;}
  }

  @include bp(br) {
    .titleRow{padding: 5px 0;}
    .titleRow{margin-bottom: 5px;}
    .inside.stip{width:75%;}
    .menuArea{padding:15px 0 30px;}
    .menuBox, .menuBoxFull{@include sec-default(); margin: 0;}

    // h1{font-size: 1.6rem;padding:10px 0;}
    // h1 span{font-size:18px;letter-spacing:0px;}
    // h3{font-size: 1.3rem;}

    // 
    .medDef{width:95%;}
    h1.pdfV{font-size:22px;margin-bottom:20px;}
    .menuImg.lunch{width:57%;}
    p{font-size:18px;}


    .special{margin-top:10px;width:57%;}
    .msInner{
      span.sm{font-size:14px;}
      h4{font-size:18px;}
      p{font-size:16px;}
    }
  }

  @include bp(tn) {
    .inside.stip{width:85%;}
  }

  @include bp(nn){
    .dailyWrap{width:90%;max-width:600px;}
  }

  @include bp(og){
    .medDef{max-width:500px;}
    h1.pdfV{font-size:20px;margin-bottom:10px;}
    .menuImg.lunch{width:100%;margin-bottom:20px;}
    .menuImg.dinner{width:100%;}
    .menuImg.solo{width:90%;max-width:500px;}

    p{font-size:17px;}
    p.menuTitle{letter-spacing:.3px;margin-bottom:5px;}
    p.menuTitle span.line1{font-size:21px;}
    p.menuTitle span.line2{font-size:19px;}

    .special{width:100%;}
    .msInner{
      h4{margin-bottom:15px;font-size:17px;}
      p{font-size:15px;line-height:1.2em;}
    }
  }

  @include bp(oc) {
    .inside.stip, .inside{width:90%;}
    .titleRow{margin-bottom: 15px;}
    .stickyCatAlt .catItem{font-size:1rem;}

    h1.pdfV{font-size:19px;}
    p.plain{font-size:15px;line-height:1.2em;}
  }

  @include bp(fi){
    .menuImg.equal{width:100%;max-width:400px;@include center-block();margin-bottom:20px;}
    .menuImg.equal+.equal{float:none;}
  }

  @include bp(sm) {
    .menuArea{padding:15px 0;}
    .scInner {@include center-block();width:90%;}
    .stickyCatAlt .catItem{margin:5px 4%;}

    .msInner h4{font-size:16px;}
  }
}

.Brunch, .Happy{
  .menuArea{padding:30px 0;margin-bottom:30px;}
  .gborder{@include sec-default();border-top:2px solid $gold;border-bottom:2px solid $gold;padding:15px 0;}
  h1{border:none;padding:0;}
  .insideN{max-width:1200px;}
  @include bp(tb){padding-top:20px;
    .menuArea{padding:20px 0;}
    .gborder{padding-top:12px;}
    .titleRow{margin-bottom:0;}
  }
  @include bp(nn){
    .med2DefB, .insideN{max-width:600px;}
    .menuArea{margin-bottom:20px;}
    .menuArea .inside{width:92%;}
  }

  @include bp(oc){
    h1{font-size:25px;}
    h2{font-size:18px;}
  }
}
.Daily{
  .menuArea{padding:30px 0;margin-bottom:50px;}
  .titleRow{margin-bottom:0;}
  .gborder{@include sec-default();border-top:2px solid $gold;border-bottom:2px solid $gold;padding:15px 0;}
  h1{border:none;padding:0;}
  p.plain{font-size:16px;}

  @include bp(tb){padding-top:20px;
    .menuArea{padding:20px 0;}
    .gborder{padding-top:12px;}
    .titleRow{margin-bottom:0;}
  }
  @include bp(nn){
    .titleWrap{width:93%;max-width:600px;}
    .menuArea{margin-bottom:35px;}
    .menuArea .inside{width:92%;}
  }
  @include bp(og){
    .menuArea{margin-bottom:25px;}
    h1{font-size:28px;margin-bottom:5px;}
    p.plain{font-size:15px;}
  }
  @include bp(oc){h1{font-size:25px;}}
}

.Lunch{background-color:$color2;}


.Lasagna{@include sec-default();background-color:$menuBlue;padding:60px;
  @include bp(og){padding:40px 0;}
  @media (max-width:500px){padding:20px 0;}
}
.lasPage{@include sec-default();padding:60px;text-align:center;background-color:$white;position:relative;z-index:0;
  &::before{@include before();width:95%;height:90%;left:2.5%;top:5%;border:1px solid rgba($gold,.5);}

  p:not(.sub), h2, h3, h4, h1{font-family:$lus;}
  h1{font-size:27px;text-transform:uppercase;color:$gold;line-height:1.1em;margin-bottom:10px;}//}//;border-top:2px solid $gold
  p{text-transform:uppercase;color:$menuBlue;margin:0px;color:$color1;font-size:19px;}
  p.sub{text-align:center;font-size:17px;letter-spacing:3px;}
  p.sub2{margin-bottom:30px;border-bottom:2px solid rgba($gold,.5);padding-bottom:15px;}

  .msInner{width:95%;max-width:500px;@include center-block();}
  span.price{color:$gold;font-weight:bold;}

  @include bp(og){padding:40px 0;}
  @media (max-width:500px){padding:20px 0;
    &::before{display:none;}
    h1{font-size:22px;}
    p{font-size:17px;}
    p.sub{font-size:15px;}
  }
}

.ccMenu{text-align:center;color:$white;
  p{text-transform:none;margin-bottom:10px;}
  a{color:inherit;text-decoration:underline;}
  iframe{width:100%;min-height:100vh;}
}