@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.OrderPage{@include sec-default();padding-top:50px;margin-top:5px;text-align:center;position:relative;z-index:0;
  &::before{@include before();width:90%;left:5%;height:1px;background-color:rgba($color1,.2);}

  h1{margin-bottom:60px;color:$color1;font-family:$port;font-size:20px;}
  .flexGroup{display:flex;flex-wrap:wrap;justify-content:center;align-items:center;grid-column-gap:30px;}
  .delItem{max-width:200px;}
  .delItem.tall{max-width:155px;}

  @media (max-width:989px){padding-top:20px;
    h1{font-size:18px;margin-bottom:30px;}
    .flexGroup{max-width:550px;}
  }

  @media (max-width:500px){padding-top:10px;
    h1{font-size:16px;}
    .flexGroup{flex-direction: column;}
    .delItem{max-width:180px;}
    .delItem.tall{max-width:145px;}
  }
}