// @import url('https://fonts.googleapis.com/css?family=Indie+Flower|Martel+Sans:400,600,800&display=swap');
@import './variables.scss';
@import './mixins.scss';

.inside, .insideAlt, .medDef, .med2Def, .med3Def, .insideXl, .insideN, .insideNrw, .insideDec, .med2DefB{ @include center-block();max-width:1600px;}
.insideN{width:95%;}
.inside{width:85%;}
.insideXl{width:90%;}
.insideAlt{width:80%;}
.medDef{width:1080px;}
.insideDec{width:300px;}
.med2DefB{width:95%;max-width:940px;}
.med2Def{width:940px;}
.med3Def{width:850px;}

@media (max-width:1235px){
  .medDef{width:90%;}
}

@include bp(br){
  .med2Def, .med3Def{width:85%;}
}

@media screen and (min-width:823px){
  .insideNrw{width:820px;}
}

@include bp(tn){
  .insideNrw{width:90%}
  .insideDec{width:80%;}
}

.fullRow{@include sec-default();}
.centerBlock{ @include center-block();}
.bmargin0{margin-bottom:0;}
.tmargin0{margin-top:0;}
.textCenter{text-align:center;}
.tpadding4{padding-top:40px;}
.tpadding6{padding-top:60px;}

.spacer1{@include sec-default();height:10px;}
.spacer2{@include sec-default();height:20px;}
.spacer25{@include sec-default();height:25px;}
.spacer3{@include sec-default();height:30px;}

hr{border-color:rgba($black,.1);
	&.hlf{width:50%;}
	&.less{width:25%;}
}
.clearfix::after, .clearfixBtn::after{
  content:"";
  clear:both;
  display:table;
}
.clearfix.btn, .clearfixBtn{@include sec-default();margin-top:20px;}
.clearBorder, .clearBorderWhite, .clearBorderLeft{@include sec-default();height:50px;position:relative;z-index:0;margin:0px 0 20px;
  &::before{@include before();width:1px;left:49.7%;background-color:rgba($black,.2);}
}
.clearBorderWhite::before{background-color:rgba($white, .3);}
.clearBorderLeft::before{left:12%;}

.mileLink{@include link(30%, $white);background-color:$color1;line-height:1em;font-size:.9rem;text-shadow:0px 1px 2px rgba($black,.3);
  &.alt{background-color:$color2;font-size:.92rem;}
  
  @include bp(tb){
    &, &.alt{font-size:15px;}
  }
  @include bp(br){
    &, &.alt{font-size:14px;}
  }
}
.textLink{font-weight:bold;
  &.und{text-decoration:underline;}
}

.simpleHeader{@include sec-default();text-align:center;padding:10px 0 35px;}

.visuallyHidden{@include visuallyHidden();}
.imgResponsive{max-width:100%;height:auto;display:block;}
ul.disc li{list-style-type:disc;}

