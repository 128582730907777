@import '../../assets/global.scss';
@import '../../assets/variables.scss';
@import '../../assets/mixins.scss';

.Social{
  display:inline-block;
  li {display: inline-block; margin:0 5px;
    &:last-child{margin-right:0;}
  }
  a, span {color: $black;}
  svg {width:18px; height:18px;}

  &.header{
    svg {width:15px; height:15px; margin-top: -3px;}
    a, span {color: $white;}
  }

  &.light {
    a, span {color: $white;}
  }
  
  &.round {
    // a, span {color: $white; background-color: $boat; border-radius: 50%; width:31px;}
    span {color: $white;}
    span {background-color: $color1; border-radius: 50%; width:31px; height:31px; line-height: 1.6em;}
  }
  
  @include bp(tb) {
    svg {width:18px; height:18px;}
  }
}