@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.Events {@include sec-default();
  .pageHd{@include sec-default(); text-align:center; padding: 30px 0 50px; position:relative;
    .phImg{display:inline-block; height:400px; margin:0 10px;}
    h1{color: $color1; font-family: $port; font-size:1.75rem; font-weight:bold; line-height: 1.4em;
      position:absolute; background-color: $white; padding: 20px 5px; text-align:center;
      width:30%; left: 35%; margin:9% 0 0;
    }

    @include bp(tw) {padding: 30px 0;
      .phImg{height:350px;}
      h1{width:38%; left: 31%; margin:11% 0 0;}
    }

    @include bp(tb) {padding: 0px 0 20px;
      .phImg{height:300px;}
      h1{font-size:1.5rem;}
    }

    @include bp(br) {
      .phImg{height:auto; width:40%; margin:0 .7%;}
      h1{font-size:1.35rem;}
    }

    @include bp(tn) {
      h1{font-size:1.2rem;}
    }

    @include bp(oc) {
      @include sec-bgimage('./images/mmExt1.jpg'); background-position:center center; padding:20px 0; margin-bottom:20px;
      .phImg{display:none;}
      h1{font-size:1.1rem; position: relative; left:auto; margin-top:0; @include center-block(); width:300px; 
        // width:300px; left: 29%; margin:11% 0 0;
      }
    }
  }

  .tockSec{@include sec-default();
    @include bp(pl){.insideAlt{width:90%;}}
    @include bp(sm){.insideAlt{width:95%;}}
  }
}

.Holiday{@include sec-default();padding:40px 0;text-align:center;position:relative;z-index:0;
  &::before{@include before();}

  h1{font-size:38px;margin-bottom:0;font-family:$port;font-weight:bold;
    span{font-size:25px;letter-spacing:2px;}
  }
  h2{font-size:28px;margin-bottom:0;}
  h3{font-size:22px;line-height:1.1em;font-weight:300;
    a{color:inherit;}
  }

  .hbody{margin-top:30px;}
  .flyer{@include sec-default();background-color:$white;
    &:hover{opacity:1;}
    &:hover img{opacity:.8;}
  }
  img{box-shadow:0px 10px 20px rgba($black, .2);}

  &.santa{
    &::before{height:60%;background-color:#B55139;}
    h1,h2,h3,p{color:$white;}
    h3{margin-top:0px;
      br{display:none;}
    }
    p{font-style:italic;font-weight:300;margin-bottom:0;}
  }

  &.pets{
    &::before{height:75%;
      background-color:$lgreen;
      background-color:#F8F8FA;
    }

    h1,h3{color:$red;}
    h2 br{display:none;}
    h3{width:96%;max-width:650px;@include center-block();margin-top:4px;line-height:1.3em;}
    h3 b{font-weight:bold;}
    p{margin-bottom:30px;line-height:1.3em;}
    p a{font-style: italic;}
    img.mob{display:none;}
    img.mob2{display:none;}
  }

  @include bp(tw){
    &.pets{
      img.fw{display:none;}
      img.mob{display:block;}
    }
  }

  @media (max-width:989px){padding:20px 0;
    h1{font-size:32px;
      span{font-size:20px;}
    }
    h2{font-size:23px;}
    h3{font-size:20px;}
    .hbody{margin-top:20px;}    

    &.pets{
      img.mob{display:none;}
      img.mob2{display:block;}
    }
  }

  @include bp(og){padding-top:5px;
    h1{font-size:26px;
      span{font-size:17px;}
    }
    h2{font-size:20px;}
    h3{font-size:18px;}
    p{font-size:16px;}
    .hbody{margin-top:15px;} 

    &.pets{padding-top:15px;
      h2{font-size:18px;line-height:1.3em;}
      h3{font-size:17px;}
    }
  }

  @include bp(oc){
    &.pets{
      h2 br.br1{display:block;}
      .dot1{display:none;}
    
    }
  }

  @include bp(sm){
    h1{font-size:22px;
      span{font-size:16px;letter-spacing:1px;}
    }
    h2{font-size:18px;}
    h3{font-size:17px;
      span{display:none;}
    }
    p{font-size:15px;}

    &.santa h3{margin-top:3px;
      br{display:block;}
    }
  }
}