@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.Home {@include sec-default(); 
  .BannerAd{@include sec-default();margin:10px 0 20px;padding:10px 0;text-align:center;background-color:#540000;box-shadow:0px 10px 20px rgba($black,.2);
    //&.xmas{@include sec-bgimage('./images/xmas.jpg');background-size:30%;background-repeat:repeat;}
    .contentSec{width:90%;max-width:500px;@include center-block();}
    &.double{padding:15px 0;
      .contentSec{max-width:800px;}
    }
    p{font-family:$port;font-weight:bold;color:$white;line-height:1.6em;font-size:18px;
      margin-bottom:2px;// ** may just want for solo + h5
      span{font-size:14px;letter-spacing:1px;}
    }
    h4{display:none;font-family:$lus;font-size:17px;color:$white;margin-top:0;text-transform:uppercase;letter-spacing:2px;
      border-bottom:1px solid rgba($white,.5);
    }
    h5{font-size:16px;color:$white;margin-top:0;letter-spacing:.3px;}
    .mileLink{background-color:$white;color:$color1;text-shadow:none;padding-top:5px;padding-bottom:6px;box-shadow:0px 10px 30px rgba($black,.3);font-size:14px;}
    .annLink{@include sec-default();margin:5px 0 15px;}

    @include bp(lg){
      .left,.right{display:inline-block;}
      .left{float:left;}
      .right{float:right;}
      .clearfix{display:none;}

      &.solo{
        // ** IF INCL date/time/price/dets 
        .right{margin-top:35px;}
        // ** IF NOT
        // .right{float:right;margin-top:21px;}        
      }

      &.double{
        .event{display:inline-block;width:50%;}
        .e1{float:left;}
        .e2{float:right;}

        .left, .right{@include sec-default();}
        .right{margin-top:5px;}
        span.s2{display:none;}
      }
    }

    // @include bp(tw){&.double .contentSec{max-width:800px;}}

    @media (max-width:900px){
      p{line-height:1.8em;font-size:17px;}
      h5{font-size:15px;letter-spacing:1px;}

      &.double{
        .contentSec{width:95%;}
        p{line-height:1.6em;margin-bottom:5px;}
        p span{font-family:$ami;font-weight:normal;text-transform:uppercase;}
      }
    }

    @include bp(og){
      &:not(.double) .right{@include sec-default();margin:10px 0 15px;}

      &.double{padding-bottom:20px;
        .contentSec{max-width:280px;}
        h4{display:block;}
        .event{@include sec-default();}
        .e1{margin:5px 0 20px;
          p span, p br{display:none;}
        }
        span.s1{display:none;}
      }
    
      &.announce p{font-size:16px;}
    }

    @media (max-width:560px){
      &.announce{
        .contentSec{width:95%;}
        p{max-width:315px;@include center-block();}
        .annLink{margin-bottom:12px;}
      }
    }
  }

  @include bp(sm){.insideXl{width:95%;}}
  .hs1{@include sec-default();text-align:center;position:relative;
    &::after{@include after();right:0;width:25%;background-color:$color2;}
    
    h1, h2, h3{font-family:$port;text-transform:uppercase;}
    h1, h2{color:$color1;margin:0;text-shadow:0px 1px 2px rgba($white,.6);}
    h1{font-size:.95rem;letter-spacing:2.5px;margin-bottom:5px;}
    h2{font-size:1.9rem;line-height:1.3em;font-weight:bold;}
    h3{font-size:1.1rem;margin:0 auto 10px;padding:7px 0;font-weight:bold;
      background-color:$color1;color:$white;width:60%;@include center-block();
    }

    .mainTitle{position:absolute;left:3%;top:5%;z-index:1;
      &::before{@include before();width:130%;height:135%;left:-15%;top:-15%;background:radial-gradient(rgba($black,.4) 35%, transparent 70%);}
      h1,h2{color:$white;text-shadow:1px 1px rgba($color1,1);}
    }
    .focal{width:75%;float:left;position:relative;
      width:100%
    }
    .textTop, .textBtm, .textCombo{position:absolute;width:45%;right:3%;}
    .textTop{margin-top:7%;}
    .textBtm{bottom:12%;}
    .deliveryLogo{display:inline-block;width:30%;margin:0 1%;vertical-align:top;background-color:rgba($white,.8);
      &.narrow{margin-top:20px;}
      &.tall{width:20%;opacity:.9;}
      &:hover{opacity:1 !important;background-color:rgba($white,1);}
      .mob{display:none;}
    }

    .textCombo{margin-top:18%;
      .deliveryLogo{background-color:rgba($white,.8);}
      .deliveryLogo.tall{opacity:.9;}
    }

    @media (min-width:1024px){.famMealsTop{display:none;}}
    @include bp(su) {
      h1{font-size:1rem;}
      h2{font-size:2.1rem;}
      h3{font-size:1.2rem;}
      .textTop, .textBtm, .textCombo{width:40%;}
    }

    @include bp(tl){
      h1{font-size:.9rem;letter-spacing:2px;}
      h2{font-size:1.7rem;}
      h3{width:70%;}
      .textTop{margin-top:5%;right:2%;}
      .textBtm{bottom:8%;right:2%;}
      .textCombo{margin-top:9%;}
    }

    @include bp(tw){
      .textTop{margin-top:2%;}
      .textBtm{bottom:4%;}
    }

    @include bp(tb){
      h1{font-size:.85rem; letter-spacing: 0px;}
      h2{font-size: 1.6rem;}
      h3{font-size: .95rem;}
      .textTop,.textBtm{width:50%;}
      .deliveryLogo{background-color: rgba($white,.8);
        &.tall{opacity:.8;}
      }
    }

    @include bp(br){
      &::after{display:none;}

      h2{font-size:1.35rem;}
      h3{font-size:.9rem;}      
      h1,h2{color:$white;text-shadow:0px 1px 2px rgba($black,.7);}
      h3{@include sec-default();margin-bottom:15px;
        br{display:none;}
      }
      
      .focal{display:none;} 
      .textTop, .textBtm, .mainTitle{@include sec-default();right:auto;bottom:auto;position:relative;top:auto;}
      .textTop,.mainTitle{min-height:300px;@include sec-bgimage('./images/mmIntroMobile.jpg');background-size:cover;background-position:center center;margin-top:0;padding:30px 0 50px;z-index:0;
        &::after{@include before();background-color:rgba($black,.4);}
      }
      .mainTitle{left:0;&::before{display:none;}}
   
      .textBtm{background-color:$white;padding-top:0px;}
      .deliverySec{padding-bottom:10px;}
      .deliveryLogo{max-width:180px;
        &.narrow{margin-top:30px;}
        &.tall{opacity:1;max-width:130px;}
      }
    }

    @include bp(oc){
      h2{font-size: 1.25rem;}
      h3{font-size: .85rem;}
      .textTop{min-height: 250px;
        &::after{background-color: rgba($black,.4);}
      }

      .deliverySec{@include sec-default();//width:80%; @include center-block();
        .dsInner{width:95%;max-width:350px; @include center-block();}
      }
      .deliveryLogo{background-color:transparent;margin:0;
        max-width:100% !important;// width:48%; -- IF ONLY NARROW
        &.tall{float:left;width:40%;}
        &.narrow{float:right;width:53%;margin:15px 0 0;}
        .fw{display:none;}
        .mob{display:block;}
      }
    }

    @include bp(ph){
      .textTop{min-height: 220px;}
    }

    @include bp(sm){
      h2{font-size: 1.2rem;}
      h3{padding: 7px 10px;}

      .textTop{min-height:175px;}
    }
  }

  .deliverOut{@include sec-default();padding-top:30px;text-align:center;
    h3{font-size:23px;font-family:$port;text-transform:uppercase;font-weight:bold;margin-bottom:20px;color:$color1;
      br{display:none;}
    }
    .deliverySec{padding-bottom:30px;border-bottom:1px solid rgba($color1, .2);}
    .dsInner{width:100%;display:flex;align-items:center;justify-content:center;grid-column-gap:30px;}
    .deliveryLogo{display:inline-block;width:33%;
      &.narrow{max-width:195px;}
      &.tall{max-width:150px;}
      &:hover{opacity:1 !important;background-color:rgba($white,1);}
    }

    @include bp(br){padding-top:0;
      h3{font-size:19px;margin:0 0 10px;padding:7px 0;background-color:$color1;color:$white;}
      
      .deliverySec{padding-bottom:15px;}
      .deliveryLogo{
        &.narrow{max-width:170px;}
        &.tall{max-width:130px;}
      }
    }
    @include bp(tn){margin-bottom:20px;}
    @include bp(oc){
      h3{font-size:18px;}

      .deliverySec{@include sec-default();
        .dsInner{width:95%;max-width:350px; @include center-block();}
      }
      .deliveryLogo{max-width:100% !important;
        &.tall{float:left;width:40%;}
        &.narrow{float:right;width:53%;margin:20px 0 0;}
      }
    }
    @include bp(sm){
      h3{font-size:17px;}
      .deliveryLogo{max-width:100% !important;
        &.narrow{margin:15px 0 0;}
      }
    }
  }

  .famMealsRow{@include sec-default();text-align:center;background-color:$color1;padding:10px;
    .contentSec{width:90%;@include center-block();max-width:600px;}
    .left,.right{display:inline-block;}
    .left{float:left;}
    .right{float:right;margin-top:6px;}

    p{font-family:$port;font-weight:bold;color:$white;}
    .mileLink{background-color:$white;color:$color1;text-shadow:none;padding-top:5px;padding-bottom:6px;box-shadow:0px 10px 30px rgba($black,.3);font-size:14px;}
    @include bp(br){display:none;}
  }

  .hs2{@include sec-default(); padding: 55px 0 30px;
    p{font-size:22px; font-family: $lus; color: $color1;}
    p span{color: darken($color2, 20%);}
    p.textIl, img{display: inline;}
    img{float:left; margin-right:15px; margin-top:-15px;}
    .textRow{@include sec-default();
      .tr1,.tr2{display:inline-block;}
      .tr1{width:49%;margin-left:3%;}
      .tr2{width:47%;float:right;}
    }

    @include bp(su) {padding:90px 0 60px;}
    @include bp(tb) {padding:55px 0 10px;
      .med2Def{width:80%;}
      img{width:170px;}
      p{font-size:20px;}
      .textRow .tr1{margin-left:2%;}
    }
    @include bp(br) {padding:45px 0 15px;
      img{max-width:170px;}

      .textRow{margin-top:10px;
        .tr1,.tr2{@include sec-default();margin-left:0;}
      }
    }
    @include bp(tn) {padding:10px 0 0px;
      .med2Def{width:90%;}
      img{width:18%; max-width:135px;margin-top:0;}
      p{font-size:19px;}
    }

    @media (max-width:800px) {padding-top:0;text-align: center;
      img{display:none;}
      .textRow{margin-top:0px;}
    }

    @include bp(sm){
      .med2Def{width:90%;}
    }
    
    // @include bp(oc) {padding: 0 0px; p{font-size: 1.05rem;}}
  }
  
  .cluster{@include sec-default(); padding: 60px 0 80px; text-align:center;
    .clusterRow{display: inline-block; width:32.5%; 
      // position: relative; z-index: 0;
      &.rLunch{float:left;}
      &.rRes{margin:0 1%;}
      &.rDinner{float:right;}
    }
    .clusterLink, img{position: relative; z-index: 0;}
    .clusterLink{z-index: 1; padding: 20px 20px; background-color: $color2;
      font-family: $port; text-transform: uppercase; font-weight:bold; color:$white; text-shadow:0px 1px 2px rgba($black,.3);
      box-shadow: 0px 5px 30px rgba($black,.3);
      &:hover{opacity: 1; background-color: darken($color2, 20%);}

      span{padding: 6px 12px; border:1px solid rgba($white,.7); border-left:0; border-right:0;}
    }

    @include bp(tw) {
      .inside{width:95%;}
      .clusterLink{font-size:.95rem;}
    }

    @include bp(tb) {padding:50px 0 60px;
      .clusterRow{width:44%; position: relative;
        &.rLunch{ z-index: 0;}
        &.rDinner{ z-index: 2;}
        &.rRes{margin-top:-14%; z-index: 1;}
      }
      .clusterLink{font-size:.9rem; padding: 15px;}
    }

    @include bp(br) {padding:45px 0 20px;
      .clusterLink{font-size:.88rem;}
    }

    @include bp(tn) {
      .clusterRow{width:48%;}
    }

    @include bp(pl) {padding: 30px 0 20px;
      .clusterRow{width:80%; 
        &.rLunch, &.rDinner, &.rRes{@include center-block(); margin:0 auto;}
        img{width:80%;}
        &.rRes{z-index:3; img{display: none;}}
      }
    }

    @include bp(oc){padding: 15px 0 0px;
      .clusterLink{@include sec-default(); box-shadow: none;}
      img{display: none;}
    }

    @include bp(sm) {
      .clusterRow{width:90%;}
    }
  }

  .famMealsTop{width:75%; @include center-block(); width:465px; margin:30px auto; background-color: rgba($white,.6); padding:10px;
    .ifm{padding: 5px 5px 15px; border:1px solid rgba($menuBlue, .6);}
    h4{font-family: $port; text-transform: uppercase; font-weight:bold; font-size: 1.05rem; margin-bottom: 12px;}
    a{letter-spacing: .5px; padding: 7px 20px 10px; 
      &:hover{cursor: pointer;}
    }

    @include bp(tw) {width:100%; margin:20px auto 30px;
      h4{font-size: 1rem;}
    }

    @include bp(tb) {margin:10px auto 30px; padding:7px;
      h4{font-size: .9rem;}
    }

    @include bp(br) {width:80%;margin:20px auto 0; padding:10px;background-color: rgba($white,.8);}
    @include bp(oc) {width:90%;h4{font-size: .85rem;}a{letter-spacing: .1px;}}
    @include bp(sm) {width:95%;h4{font-size: .8rem;}}
  }

  @include bp(tn) {.inside.outerIn{width:95%;}}

  .famMeals{@include sec-default(); text-align:center; padding:30px 30px 40px; background-color: rgba($color2,.85); margin:30px 0 50px;
    .ifm{@include sec-default(); padding:30px 10px; background-color: $white;}
    .titleRow{@include sec-default(); text-align: center; padding: 5px 0; margin:5px 0;  border-top: 2px solid $menuBlue; border-bottom: 2px solid $menuBlue;}// padding: 10px 0; margin:5px 0 20px; 
    .med2Def{width:95%;max-width:750px;}
    .menuBoxFull{@include sec-default(); margin:5px 0 10px;}
    
    p, h3, h4, h1{font-family: $lus; text-transform: uppercase; color: $menuBlue;}
    h3{font-weight: bold; font-family: $port; font-size: 1.3rem;}
    h4{color: $gold; margin-bottom:0; font-weight: bold;
      span{padding-left:7px; color: $menuBlue;}
    }
    p.call{font-size:1.3em; font-family: $lus; 
      a{text-transform: none; color: darken($color2, 20%);}
    }
    p{margin-top: 0px;}
    p.sub{color: $black; font-size:1.15rem;border-top: 2px solid $gold;border-bottom: 2px solid $gold; padding: 10px 0;}

    .togoDisc{@include sec-default(); background-color: $color1; text-align: center; padding:15px 0;
      p{color:$white; margin:0; line-height: 1.5em; font-size:19px;}
      @media (max-width:1200px){
        p{width:80%; @include center-block();}
      }

      @media (max-width:639px){
        p{width:90%; font-size:17px;}
      }
    }

    @include bp(tw) {
      h3{font-size: 1.2rem;}
      p.sub{font-size: 1.1rem;}
    }

    @include bp(tb) {padding:30px;margin-bottom:20px;
      .ifm .inside{width:90%;}
      .titleRow{margin:5px 0 10px;}
      .menuBoxFull{margin:3px 0;}
      h3{font-size: 1rem; margin-bottom: 5px;}
      p.call{font-size:1.15em;}
      p.sub{font-size: 1rem; margin-bottom: 0px;}
    }

    @include bp(tn) {padding:20px; margin-top:40px;
      .ifm{padding:20px 10px;}
      .ifm .inside{width:95%;}
      .menuBoxFull{margin:0px 0 5px;}
      p.call{font-size:1.05em;}
    }

    @include bp(oc) {padding:10px; margin:30px 0 0px;
      .ifm{padding:10px 10px 5px;}
      .ifm .inside{width:95%;}
      .menuBoxFull{margin:0px 0 5px;}
      h3{font-size: .95rem;}
      p.call{font-size:.95em;}
    }
  }



  // event flyers
  .flyers{@include sec-default();padding:60px 0;text-align:center;
    .medDef{max-width:1000px;}
    .flyer{display:inline-block;width:45%;max-width:450px;box-shadow:0px 10px 20px rgba($black,.2);}

    @media (min-width:640px){
      .f1{float:left;}
      .f2{float:right;}
    }

    @include bp(tw){padding-top:40px;}
    @include bp(br){padding-top:25px;padding-bottom:40px;margin-top:20px;border-top:1px solid rgba($black,.1);
      .medDef{max-width:700px;}
      .flyer{width:48%;max-width:300px;}
    }
    @include bp(oc){padding-bottom:30px;
      .flyer{width:100%;float:none;margin:auto;}
      .f1{margin-bottom:20px;}
    }
  }

  .inFly+.hs2{padding-top:0;}


} 