@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.WineDinner {@include sec-default(); background-color: $menuBlue; padding: 40px 0px;
  .menuArea{@include sec-default();background-color: $white; padding:50px 0;}
  .titleRow{@include sec-default(); text-align: center; border-top: 4px solid $menuBlue; border-bottom: 4px solid $menuBlue; padding: 10px 0; margin-bottom: 15px;}
  .inner{@include sec-default();border-top: 2px solid $gold;border-bottom: 2px solid $gold; padding: 10px 0;}
  .menuRow {@include sec-default();position: relative; z-index: 0; margin-top:20px;
    &::before{@include before(); @include sec-bgimage('./images/compass.png'); background-position: center center; z-index: -2;}
    &::after{@include before(); background-color: rgba($white, .97);}
  }
  .menuBoxFull{width:80%;max-width:640px;@include center-block();text-align:center;margin-bottom:40px;}

  p, h4, h5, h1{font-family:$lus;text-transform:uppercase;}
  h1{color:$menuBlue;font-weight:bold;}
  h1 {line-height:1.2em;
    span{font-size:1.7rem;color:darken($color2,8%);}
  }
  h2{font-size: 1.3rem; margin-bottom:0;}
  h2 br.br1{display: none;}
  h3{font-size:20px;color:$gold;margin-top:15px;}
  .titleRow h4{font-size:1.3rem;margin-bottom:0;color:$menuBlue;}
  a{text-transform: none; color:darken($color2,8%);}

  h4{color:$gold;margin-bottom:0;line-height:1.3em;
    span{text-transform:none;font-size:17px;color:$menuBlue;font-style:italic;}
  }
  p{margin-top:0px;text-transform:none;color:$menuBlue;}
  h5{font-size:19px;font-family:$port;font-weight:bold;color:$black;}
  p.pair{font-family:$ami;line-height:1em;color:darken($color2,12%); margin-bottom:10px;}

  // NON WINE EVENT 
  p.subtitle{color:$gold;font-family:$port;text-transform:none;margin-bottom:15px;font-size:16px;}
  .titleRow{margin-bottom:0px;
    .inner{padding-bottom:15px;}
  }
  .mileLink{color:$white;text-transform:uppercase;padding:8px 20px 10px;letter-spacing:1px;}
  p.eventDets{font-size:19px;margin-bottom:20px;}
  ul p, li{font-family:$port;font-size:19px;}
  ul {@include sec-default();margin:10px 0;
    p{font-weight:bold;}
  }
  li{@include sec-default();color:$gold;position:relative;z-index:0;padding-bottom:16px;
    &::before{@include after();width:5px;height:5px;left:50%;margin-left:-2.5px;bottom:0px;border-radius:50%;background-color:$color1;}

    &:last-child{color:$color1;}
    &:last-child::before{display:none;}
  }
  // NON WINE EVENT 


  // PEARL EVENT 
  &.pearl h1{font-size:40px;margin-bottom:0;line-height:1em;}
  &.pearl h5{margin-bottom:20px;line-height:1.7em;}
  .btnRow{@include sec-default();margin:20px auto 10px;}
  .mileLink.alt{color:$color1;text-shadow:none;box-shadow:0px 10px 20px rgba($black,.12);}

  &.pearl .menuRow{text-align:center;}
  &.pearl h4{font-size:20px;line-height:1.7em;text-transform:none;margin-bottom:20px;}
  &.pearl h4 span{font-style:normal;font-size:22px;}
  h4.h41{color:$color1;}
  .fullRow{margin-top:30px;}
  .imgHlf,.textHlf{display:inline-block;}
  .imgHlf{float:left;width:65%;}
  .textHlf{width:32%;float:right;margin-top:10%;}

  @include bp(tw){
    .imgHlf{width:62%;}
    .textHlf{width:35%;margin-top:5%;}
  }
  // PEARL EVENT 


  @include bp(br) {padding: 20px 0px;
    .inside.stip{width:90%;}
    .menuArea{padding:30px 0 15px;}
    h1 {font-size: 1.65rem;
      span{font-size: 1.45rem}
    }
    h2{font-size: 1.1rem;}
    h3{font-size:18px;}
    .titleRow h4{font-size: 1.1rem;}
    .menuBoxFull{margin-bottom:30px;
      h4{line-height:1.3em;font-size:17px;}
      h5{font-size:15px;}
      p:not(.pair){font-size:16px;}
      p.pair{font-size:15px;}
    }


    // NON WINE EVENT 
    p.subtitle{font-size:14px;}
    .mileLink{font-size:14px;}
    p.eventDets{font-size:17px;margin-bottom:10px;}
    ul p, li{font-size:17px;}
    // NON WINE EVENT 


    // PEARL EVENT 
    .imgHlf{width:55%;}
    .textHlf{width:42%;margin-top:0;}
    &.pearl h4.h42{margin-top:0;line-height:1.5em;}
    &.pearl h4.h42, &.pearl h4 span{font-size:19px;}
    // PEARL EVENT 
  }

  @include bp(og){
    // NON WINE EVENT 
    .inside{width:95%;}
    ul p, li{font-size:15px;}
    li{padding-bottom:16px;
      &::before{width:3px;height:3px;margin-left:-1.5px;}
    }
    // NON WINE EVENT 

    // PEARL EVENT 
    &.pearl h1{font-size:35px;
      span{font-size:22px;}
    }
    &.pearl h2{font-size:17px;}
    .mileLink{font-size:13px;}

    &.pearl h5{font-size:17px;margin-bottom:10px;}
    &.pearl h5 br{display:none;}
    h4.h41{font-size:18px;margin-top:0;}
    .fullRow{margin-top:0px;}
    .imgHlf{width:90%;@include center-block();}
    .textHlf{@include sec-default();margin-bottom:30px;}
    &.pearl h4.h42, &.pearl h4 span{font-size:18px;}
    // PEARL EVENT 
  }

  @include bp(pl) {padding: 20px 0px;
    .menuArea{padding:20px 0 5px;}
    h1 {font-size: 1.5rem;
      span{font-size: 1.35rem}
    }
    h2,.titleRow h4{font-size: 1.05rem;}
    h3{font-size:17px;}
    .menuBoxFull{margin-bottom:30px;}
  }

  @include bp(oc) {
    h1 {font-size:21px;
      span{font-size:19px;}
    }
    h2, h3, .titleRow h4{font-size:16px;}
    h2{
      br.br1{display:block;}
      span.sp1{display:none;}
    }

    .menuBoxFull{width:100%;}

    // PEARL EVENT 
    &.pearl h1{font-size:30px;
      span{font-size:19px;}
    }
    // PEARL EVENT 
  }

}