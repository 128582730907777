@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.Newsletter{text-align: left;
  .label{font-size: 18px; font-weight: 500;}
  .email{@include sec-default(); margin-top:10px; margin-bottom: 10px; min-height: 40px; padding-left: 6px; border:0 !important; border-bottom: 2px solid rgba($color1, .3) !important; background-color: $fa;}
  .mileLink{width:130px; padding:5px auto; border: 0 !important;}
}

.Newsletter.header{text-align: center;
  .clearfix{display: none;}
  input.email,.mileLink, .label{display: inline-block; float:none;}
  .label {color: $white; font-size: 15px;}
  .mileLink{background-color: $white; color:$color1; padding: 6px; width:115px;font-size: 14px;}
  input.email{width:300px; margin:0 10px; font-size: 14px; border-radius: 4px; min-height: 33px;}

  @include bp(tw) {
    .label{font-size:14px;}
    input.email{min-height: 30px; width:270px;}
  }

  @include bp(tb) {
    input.email{min-height: 30px; width:270px;}
    .mileLink{padding:5px; width:110px;}
  }

  @media (max-width:767px) {
    input.email{min-height: 30px; width:270px;}
    .mileLink{width:auto;padding:5px 10px;}
  }

  @media (max-width:570px) {
    .mileLink{margin-top:5px; float:right; margin-right:10%;}
  }

  @media (max-width:470px) {
    .label{width:100%;}
    input.email, .mileLink{font-size: 13px; float:none; margin:auto;}
    input.email{width:250px;margin-right:5px;}
  }
}

.resComp.contactPage, .dirComp.mileLink{padding:7px 20px 8px;}