@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.Header {@include sec-default(); 
  .dtoBar{@include sec-default(); padding:10px 0; text-align:center;
    .dtoItem{display:inline-block;vertical-align:top;margin:5px;}
    a{@include sec-default();font-size:15px;padding:10px 20px;margin:0;line-height:1em; text-transform:uppercase;letter-spacing:1px;font-weight:bold;
      background-color:$burg;color:$white; box-shadow:0px 5px 30px rgba($black,.3);
    }

    @media(max-width:500px){
      a{box-shadow: none; font-size: 14px;letter-spacing:.3px;}
    }
  }

  
  .topBar{@include sec-default(); background-color: $color1; padding: 5px 0; color: $white;text-align: center;
    .insideAlt{width:90%;}
    .tbh{display:inline-block;}
    .hlfa, .hlfc{margin-top:3px;}
    .hlfa{float: left;text-align: left;}
    .hlfc{float:right;text-align: right;}
    .hlfb{text-align: center; float:none;}
    p,a {color: $white; font-size: 15px;}
  }
  .navMm{@include sec-default();padding:10px 0;background-color:transparent;}
  .navWrap{width:90%;max-width:1200px;}

  .mobNav{display: none; float:right; margin-top:30px;
    svg{width:30px; height:30px; color:$color1;}
  }
  
  .logoBlk, .navBarCustom, .navItem{display:inline-block;}
  .logoBlk{float:left;padding:0;margin:0;min-height:auto;}
  .ilLogo{max-width:350px;}
  .navBarCustom{float:right;margin-top:8px;text-align:right;
    a:hover, li a:hover{color: $gold; opacity: 1;}
  }
  .logoBlk{margin-left:-5%;}
  .navBarCustom{margin-right:-5%;}
  
  .navItem{margin:0;
    a{color:$color1;text-transform:none;font-family:$port;}
  }

  .custDrop{padding:5px 0; margin-top:0; text-align: left;
    .dropLi{@include sec-default(); margin: 5px 0;
      a{padding: 0px 0 0px 20px; color: $color1; font-size: .9em;}
      &.holiday a{color:$red;}
      &.emph a{color:$red;}
    }
  }

  .eventDrop{width:280px;}

  @include bp(tl){
    .logoBlk{margin-left:0;}
    .navBarCustom{margin-right:0;}
  }

  @media (max-width:1300px){text-align: center;
    .logoBlk{max-width:300px;float:none;margin:auto;}
    .ilLogo{width:100%;}
    .navBarCustom{@include sec-default();text-align:center;margin-top:0;}
    .navItem > a{margin:0;min-height:70px;}
  }

  @include bp(tw){
    .topBar{
      .hlfa, .hlfc{margin-top:2px;}
      p,a {font-size: 14px;}
    }
  }

  @include bp(tb){
    .topBar{
      .insideAlt{width:95%;}
      p,a {font-size: 14px;}
    }

    .navBarCustom{display:none;}
    .mobNav{display:inline-block;}

    .navMm{padding:5px 0 0;}
    .medDef{width:600px;}
  }

  @include bp(br) {
    .topBar{
      .insideAlt{width:650px;}
      .hlfa, .hlfb, .hlfc{@include sec-default(); text-align: center; margin:2px 0 5px;}
      .hlfb{margin-top: 5px;}
    }
  }

  @media (max-width:767px) {
    .topBar .insideAlt{width:90%;}
  }

  @include bp(oc) {
    .medDef{width:85%;}
    .logoBlk{max-width:220px;}
    .mobNav {margin-top: 17px;}
  }

  @media (max-width:470px) {
    .topBar{
      .insideAlt{width:95%;}
      .hlfa, .hlfb, .hlfc{margin:0px 0 3px;}
    }
  }

  @include bp(xx) {
    .logoBlk{max-width:200px;float:left;}
    .mobNav {margin-top: 12px;}
  }
}

.SideNavList{padding:0px 10px 0 10px; width:300px;
  .SideNav{margin-top:10%;}
  .closeIcon {color: $color1; position: absolute; right:20px; top:15px; cursor: pointer; font-weight: normal;
    svg{width:30px; height:30px;}
  }
  hr{@include sec-default();}

  .navItem {@include sec-default(); margin:0; padding: 15px 15px;
    &.niBtn{background-color: rgba($color2, .4); padding: 10px 15px; margin: 5px 0;}
    a{font-weight:bold;color:$color1;text-transform:none;font-family:$port;font-size:.9rem;}
    &.holiday a{color: $red;}
    &.emph a{color: $red;}
  }

  .devBox{@include sec-default();margin-top:20px;background-color:#f8f8f8;padding:15px 5px 5px 15px;
    p{font-weight:bold;color:darken($color2,15%);text-transform:none;font-family:$port;font-size:16px;padding:0;margin:0 0 15px;}
    li{@include sec-default();margin-bottom:10px;}
  }
}

