@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.Contact{@include sec-default();margin-bottom:50px;
  @include bp(br){margin-bottom:0px;}

  .stack2{@include sec-default();padding:50px 0;position:relative;z-index:0;
    h1{color:$color1;margin:0;font-family:$port;}
    .longImg, .tallImg{position:relative;}
    .tallImg{width:40%;float:left;margin-left:2%}
    .longImg{float:right;width:55%;margin-right:1%;margin-top:5%;}

    .textBox1 {position:absolute;width:20%;background-color:$white;padding:10px 0;left:35%;top:36%;text-align:center;z-index:1;}
    .textBox2{width:55%;float:right;text-align:center;}
    .clearfixBtn{margin-top:10px;}
    .mileLink{padding:7px 20px 8px;}

    .il1, .il2{display:inline-block;vertical-align:top;margin:0 4%;}
    p{font-size:1.1rem;}
    p span{font-size:.8rem;font-family:$port;color:$gold;font-weight:bold;}
    p.hours{font-family:$port;color:$color1;margin:20px 0 10px;font-weight:bold;line-height:1.8em;
      b{border-bottom:1px solid $color1;}
    }

    @include bp(su) {
      .textBox1 {width: 16%;}
    }

    @include bp(tw) {padding: 20px 0 0; 
      h1{font-size:1.8rem;}
      .tallImg{margin-left:0;}
      .longImg{margin-right:0;width:58%;}
      .textBox1 {width: 25%;}
      .textBox2{ width:58%;}
      .il1{float:left; margin: 0 0 0 2%;}
      .il2{float:right; margin: 0 5% 0 0;} 
    }

    @include bp(tb) {padding: 0px 0 0; 
      .insideAlt{width:90%;}
      h1{font-size:1.5rem;}
      p{font-size: 1rem;}
      p.hours{font-size:.95rem;}
      .longImg{margin-top: 3%;}
    }

    @include bp(br) {
      h1{font-size:1.3rem;}
      .stackTop{@include sec-default(); text-align: center; height:375px;
        background-image: url('../../assets/images/mmExt5mobile.jpg'), url('../../assets/images/mmFront3mobile.jpg');
        background-size: 31%, 67%; background-position: center left, center right; background-repeat: no-repeat, no-repeat;
      }
      .longImg, .tallImg{display:none;}
      
      .textBox1 {width: 250px; left:22%; top:27%;}
      .textBox2{@include sec-default(); margin-top: 5px;}
      .il1, .il2{@include sec-default(); margin:0;}
      p.hours{font-size:.9rem;}
    }

    @include bp(tn) {
      h1{font-size:1.2rem;}
      .textBox1 {top:20%;}
      .stackTop{height:260px;}
    }

    @include bp(oc) {
      h1{font-size:1.1rem;}
      .textBox1 {width:190px; top:15%; left:17%;}
      .stackTop{height:200px;}
    }

    @include bp(sm) {
      h1{font-size:1rem;}
      .textBox1 {width:165px; top:12%; left:17%;}
      .stackTop{height:130px;}
      p.hours{font-size:.85rem; margin:10px 0 5px;}
    }
  }
}