@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.Footer {@include sec-default(); text-align: center; padding:30px 0 0;
  // .partners{display: inline-block; margin: 10px 2%;
  //   width: 18%;
  //   &.yoho img{padding:8px}
  // }
  // img{@include center-block();}

  position: relative;
  &::before{@include before(); width:1px; left: 60%; height:80%; top: 10%; background-color: rgba($black,.2);}
  .others, .mailer{display: inline-block;}
  .others{width:60%; float:left;}
  .mailer{width:33%; float:right; margin-top: 35px;} 

  .partners{display: inline-block; margin: 10px 1%;
    width: 30%;
    &.yoho img{padding:8px}
  }
  img{@include center-block();}

  .copyHs{@include sec-default(); margin-top:15px;
    position: relative; z-index: 0;
    // p,a{color: $white;}
    p{display: inline-block; font-size: 14px; margin:10px 0 5px;}
    p.copy{float:left;}
    p.hs{float: right; text-align: right;}  
    
    @include bp(mb) {
      p, p.hs{@include sec-default(); text-align: center; margin:5px 0 0;}
    }
  }

  @include bp(su) {
    &::before{left: 57.5%;}
  }

  @include bp(tb) {
    .mailer{width:35%;}
  }

  @include bp(br) {
    &::before{display: none;}
    .others, .mailer{width:70%; @include center-block();}
    .others{width:85%; margin-bottom: 20px;}
    .partners{width:29%; max-width:194px; margin: 0px 2%;}
  }

  @include bp(oc) {padding:0px 0 0;
    .others{@include sec-default();}
    .mailer{width:80%;}
  }
  
  @include bp(ph) {
    .others{margin-bottom: 10px;}
    .partners{width: 160px; @include center-block(); margin-bottom: 10px;}
    .mailer{width:90%;}
  }
}