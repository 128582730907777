@font-face {
  font-family: 'Portmanteau Regular';
  src: url('./assets/fonts/Portmanteau Regular.eot');
  src: url('./assets/fonts/Portmanteau Regular.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/Portmanteau Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Portmanteau-Regular';
  src: url('./assets/fonts/Portmanteau-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

