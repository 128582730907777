@import url('https://fonts.googleapis.com/css2?family=Lusitana:wght@400;700|Amiri:wght@400;700&display=swap');
// 

@import "./assets/global";
@import "./assets/variables";
@import "./assets/mixins";

// ::selection{background: boat;}
// ::-moz-selection {background: boat;}
*, *::before, *::after {box-sizing: border-box;}

html {line-height: 1.5em; font-size: 17px; color:$black;
  h1, h2, h3, h4, h5, p, li, a, span {color: $black; line-height: 1.5em; margin: 8px 0;
    // font-family:body;
    &.bmargin0 {margin-bottom: 0;}
  }
  p,li{font-weight: 400;}
  p{font-size: 1rem; margin-top:7px;}
  span{color:inherit;}
  
  h1{font-size: 2.1rem; line-height: 1.3em; font-weight:bold;}
  h2{font-size:1.5rem;}
  h3{font-size: 1.4rem;}
  h4{font-size:1.1rem;}

  @include bp(tb) {
    h1{font-size: 1.9rem;}
    h2{font-size:1.3rem;}
    h3{font-size: 1.2rem;}
    h4{font-size:1rem;}
  }
}

a {text-decoration: none;
  &:hover {opacity: .8; text-decoration: none; @include ease(.3s);}
}
button{background: none; border:none; border-radius:0; font-size:inherit; color:inherit; font-weight:inherit; letter-spacing: inherit;}
img{@include center-block();}
ul {margin:0; list-style-type: none; padding-left: 0;}
li{list-style-type: none;}

.markerWrap{@include sec-default(); 
	.Content{@include sec-default(); min-height:65vh;}
  @include bp(og){.Content{min-height:auto;}}
}

.ToTopBtn{margin-right:-15px; margin-bottom: 20px;}