@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.KidsMenu {@include sec-default(); position: relative;
  img{box-shadow: 0px 5px 30px rgba($black,.2);}
  .textBox{position: absolute; width:45%; left: 27.5%; top:5%; padding:30px 30px 15px; background-color: $white; border: 5px solid $black; text-align: center;}
  h1, p{font-family: $port;}
  h1{margin-bottom:0px;}
  hr{margin:10px auto 25px; width:35%; @include center-block();border-color: rgba($black,.2);}
  p{margin-bottom: 20px;}
  p.menuSub{margin:15px 0 10px; font-size: .9rem;}
  br.sm{display: none;}

  @include bp(su) {
    .textBox{width:35%; left: 32.5%;}
  }

  @include bp(tb) {
    .textBox{width:60%; left: 20%;}
    h1{font-size: 1.6rem;}
    p{font-size: .9rem;}
    p.menuSub{font-size: .85rem;}
  }

  @include bp(tn) {
    .textBox{width:75%; left: 12.5%;}
    h1{font-size: 1.45rem;}
    p{font-size: .85rem;}
    p.menuSub{font-size: .8rem;}
  }

  @include bp(oc) {
    .textBox{@include sec-default(); position: relative;left:0; padding:10px 5px 0px;}
    h1{font-size: 1.25rem;}
    br.sm{display: block;}
    p.menuSub{margin-top:10px; line-height: 1.7em;}
  }

  @include bp(sm) {
    .inside{width:95%;}
    h1{font-size: 1.15rem;}
    hr {margin-bottom: 15px;}
  }
}