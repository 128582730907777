$fa: #fafaf8;
$fa2: darken($fa, 1%);
$df: #dfdfdf;
$white: #fff;
$black: #000;
$red: #bb2830;
$green:#008000;
$lgreen:#e5f2e5;
$burg: #870000;
$color1: #053d57;
$color2: #a2b4d7;
$gold: #93876b;
$menuBlue: #2b4271;
$orange:#ff9200;
$text-lt:#e7e9ee;
$border: 1px solid rgba($black,.1);

$port: 'Portmanteau Regular';
$lus: 'Lusitana', serif;
$ami: 'Amiri', serif;

$semi: 500;
$light: 300;